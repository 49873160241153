<template>
    <router-view />
    <cookie-law></cookie-law>
</template>

<script lang="ts" setup>
import CookieLaw from '@modules/Core/components/app/CookieLaw.vue'
import useLayoutStore from '../stores/layout'
import { useQuasar } from 'quasar'
import { definePalette } from '../helper/theme'
import useUserStore from '../stores/user'
import i18nLib from '@src/plugins/i18n'
import * as icons from 'simple-icons'

const layoutStore = useLayoutStore()
const userStore = useUserStore()
const quasar = useQuasar()

quasar.iconMapFn = function (name) {
    if (name.startsWith('si')) {
        //@ts-ignore
        if (icons[name]) {
            return {
                //@ts-ignore
                icon: icons[name].path,
            }
        }
    }
}
onMounted(() => {
    i18nLib.global.locale.value = layoutStore.locale
    layoutStore.changeLocale(layoutStore.locale, quasar)
    definePalette(layoutStore.theme.light)
})

if (userStore.isLoggedIn) {
    // On refresh page, reload necessary data
    userStore.updateRoutes()
    userStore.updateModules()
    userStore.updatePermissions()
}
</script>
