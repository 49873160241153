import type { App } from 'vue'
import { Quasar, Loading } from 'quasar'
import quasarIconSet from 'quasar/icon-set/svg-mdi-v7'

// Import icon libraries
//import '@quasar/extras/material-icons/material-icons.css'
//import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'

// A few examples for animations from Animate.css:
// import @quasar/extras/animate/fadeIn.css
// import @quasar/extras/animate/fadeOut.css

// Import Quasar css
//import 'quasar/src/css/index.sass'

export default function (app: App) {
    app.use(Quasar, {
        plugins: {
            Loading,
        }, // import Quasar plugins and add here
        iconSet: quasarIconSet,
        config: {
            brand: {
                primary: '#4392F1',
                secondary: '#e0e0e0',
                accent: '#253C78',

                dark: '#1D1D1D',

                positive: '#8bc34a',
                negative: '#f44336',
                warning: '#ff9800',
                info: '#00bcd4',
            },
        },
    })
}
