import { defineStore } from 'pinia'
import pinia from '@src/plugins/pinia'
import axios from '@src/libs/axios'
import useWebStore from '@src/stores/web'
import useLayoutStore from '@src/stores/layout'
import { views } from '@src/modules'
import type { User } from '../models/User'
import type { Route } from '../models/Route'
import type { ResourceArray } from '../types/resource'

export type UserState = {
    user: User | null
    businessId: Number | null

    permissions: string[]
    routeItems: Route[]
    notifications: []
    loadingRoutes: boolean
    hasMultipleSystems: boolean
    isAdmin: boolean
    isMaster: boolean
    isLoggedIn: boolean
    modules: RequestModules
}

type UserAuth = {
    user?: User
    admin: boolean
    token: String
    token_type: String
    expires_it: Number
    business?: Number
}

type RequestModules = {
    data: string[]
    avaliable: string[]
}

const useUserStore = defineStore('user', {
    state: () =>
        ({
            user: null,
            businessId: null,
            permissions: [],
            routeItems: [],
            notifications: [],
            loadingRoutes: false,

            // boolean vars
            hasMultipleSystems: false,
            isAdmin: false,
            isMaster: false,
            isLoggedIn: false,
            modules: { data: [], avaliable: [] }, //Módulos que o cliente logado tem acesso
        }) as UserState,
    actions: {
        hasPermission(permissions: string | string[]) {
            const isOwner = this.permissions.includes('owner')
            if (Array.isArray(permissions)) {
                return this.permissions.some((permission) => {
                    if (permission != 'logout-partner' && isOwner) return true
                    return permissions.includes(permission)
                })
            }
            if (permissions != 'logout-partner' && isOwner) return true
            return this.permissions.includes(permissions)
        },
        hasModule(modules: string | string[]) {
            if (Array.isArray(modules)) {
                return this.modules.data.some((module) => {
                    return modules.includes(module)
                })
            }
            return this.modules.data.includes(modules)
        },
        async updateBusiness(businessId: Number | null) {
            if (this.businessId == businessId) return
            this.businessId = businessId
            if (businessId == null) {
                this.permissions = []
                this.modules = { data: [], avaliable: [] }
                return
            }
            await Promise.all([
                this.updateModules(),
                this.updatePermissions(),
                this.updateRoutes(),
            ])
        },
        async updatePermissions() {
            const webStore = useWebStore(pinia)
            this.permissions = ['any']

            if (!this.isLoggedIn) {
                return
            }
            try {
                this.permissions = (
                    await webStore.get<{
                        data: string[]
                    }>({
                        url: 'core/info/permissions',
                        locale: '[Get my Permissions]',
                        silent: true,
                    })
                ).data.data
            } catch (e) {}
        },
        async updateModules() {
            const webStore = useWebStore(pinia)
            if (!this.isLoggedIn || this.isAdmin) {
                return
            }
            try {
                this.modules = (
                    await webStore.get<RequestModules>({
                        url: 'core/info/business/modules',
                        locale: '[Get Modules from Business of User]',
                        silent: true,
                    })
                ).data
            } catch (e) {
                this.modules = { data: [], avaliable: [] }
            }
        },
        async loginAdmin(user: User) {
            let response = await axios.post<UserAuth>('/auth/login/admin', {
                email: user.email,
                password: user.password,
            })
            if (response.data.user) {
                this.user = response.data.user
                this.isAdmin = true
                this.isLoggedIn = true
                this.businessId = null
            }
            return response
        },
        async loginUser(user: User) {
            let response = await axios.post<UserAuth>('/auth/login', {
                email: user.email,
                password: user.password,
            })
            if (response.data.user) {
                this.user = response.data.user
                this.isAdmin = false
                this.isLoggedIn = true
                this.isMaster = response.data.admin
                this.businessId = null
            }
            this.updateBusiness(response.data.business ?? null)
            return response
        },
        async logout() {
            if (this.hasPermission('logout-partner')) {
                const url = 'partnersystem/logout'
                const response = await axios.post<{
                    business: Number
                }>(url)
                window.router.push('/')
                await Promise.all([
                    this.updateBusiness(response.data.business),
                    useLayoutStore().updateTheme(),
                ])
                return
            }
            const url = this.isAdmin ? '/auth/logout/admin' : '/auth/logout'
            const wasAdmin = this.isAdmin
            this.isAdmin = false
            this.isLoggedIn = false
            this.user = null
            this.businessId = null
            this.modules = { data: [], avaliable: [] }
            await this.updateRoutes()
            window.router.push(wasAdmin ? '/login/admin' : '/login/user')

            await axios.post(url).catch((error) => {
                return error
            })
        },
        async refreshToken() {
            const url = this.isAdmin ? '/auth/refresh/admin' : '/auth/refresh'
            const response = await axios.put<UserAuth>(url)
            if (response.status == 401 || response.status == 403) {
                throw response
            }

            // When token is expired, on the refresh request the user return null
            if (response.data.user) this.user = response.data.user
            this.isLoggedIn = true

            return response
        },
        async updateRoutes() {
            const webStore = useWebStore(pinia)

            if (!this.isLoggedIn) {
                if (window.router) window.updateRouter()
                return
            }
            this.loadingRoutes = true
            if (this.isAdmin) {
                this.routeItems = [] as Route[]
                const result = await Promise.all(
                    views['Admin'].map(async (element: any) => {
                        return await element.import()
                    })
                )
                for (const view of result as any[]) {
                    const page = view.default
                    let route = {
                        name: page.name,
                        location: 'Admin@' + page.name,
                        title: 'Admin.routes.' + page.name,
                        icon: page.icon || 'mdi-account-question-outline ',
                        to: page.path || '/admin/' + page.name,
                        path: page.path || '/admin/' + page.name,
                    } as Route

                    if (page.fatherDir) {
                        let father = this.routeItems.find((aux) => {
                            return aux.title == 'Admin.routes.' + page.fatherDir
                        })
                        if (father) {
                            if (!father.routes) father.routes = []
                            father.routes.push(route)
                        } else {
                            father = {
                                title: 'Admin.routes.' + page.fatherDir,
                                icon: 'mdi-folder',
                                routes: [route],
                            } as Route
                            this.routeItems.push(father)
                        }
                    } else {
                        this.routeItems.push(route)
                    }
                }
            } else {
                try {
                    const response = await webStore.get<ResourceArray<Route>>({
                        url: 'core/info/routes',
                        locale: '[Get Routes User]',
                    })
                    this.routeItems = response.data.data
                } catch (response) {
                    this.routeItems = []
                }
            }

            if (window.router) window.updateRouter()
            this.loadingRoutes = false
        },
    },
    persist: true,
})

export default useUserStore
